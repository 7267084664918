import React from 'react'
// import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../layout/Layout'
import Seo from '../components/Seo'
import { NotAvailable } from '../sections/index'

const IndexPage = () => {
  // const dataImage = useStaticQuery(graphql`
  //   query {
  //     notAvailable: file(relativePath: { eq: "stock/allency-in-construction-spanish.png" }) {
  //       childImageSharp {
  //         gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
  //       }
  //     }
  //   }
  // `)

  // const notAvailable = dataImage.notAvailable.childImageSharp.gatsbyImageData

  // const contents = {
  //   image: notAvailable,
  // }

  return (
    <Layout>
      <Seo
        lang={'es'}
        title={'Allency'}
        description={"Agencia de publicidad"}
        keywords={['Publicidad,Aliados,Estrategia,Marketing,Agencia,Digital,Resultados,Branding,Naming,Logotipo,Brochure,Creativo,Plan,Medios,Campañas,Programación,Código,Posicionamiento,Tráfico,Interfaces,Gestión,Multimedia,Diseño,Proyecto,Planes,Soluciones,Calidad,Startups,Pymes,Empresa,Eventos,Corporativos,Marcas,Portafolio']}
        noIndex={''}
        noFollow={''}
        slug={'start-your-project'}
        canonicalUrl={'https://allency.com.mx/start-your-project'}
        ogTitle={'Allency'}
        ogType={'website'}
        ogDescription={"Agencia de publicidad"}
        ogImage={'../../images/logos/allency-dark.png'}
      />
        <NotAvailable />
    </Layout>
  )
}

export default IndexPage